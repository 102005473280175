import { useState, useEffect } from "react";
import { useEffectOnce } from "react-use";
import styled from "styled-components";
import ReactGA from "react-ga4";

import { LoadingPage } from "components";
import { Features } from "./components/Features";
import { PricingTable } from "./components/PricingTable/PricingTable";
import { BuyCreditModal } from "./components/BuyCreditsModal";
import { CancelPaymentModal } from "./components/CancelPaymentModal";
import { InvoiceModal } from "./components/InvoiceModal";
import { SuccessModal } from "./components/SuccessModal";

import { useAppNotification } from "hooks/services/AppNotification";
import useRouter from "hooks/useRouter";

import {
  getPlan,
  getActivePlan,
  upgradePlan,
  confirmUpgrade,
  getCredits,
  addCredits,
  cancelSubscription,
  ICredit,
  IPlan,
  IUser,
  EPlanDuration,
  CustomerPortal,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import { usePrevious } from "hooks/usePrevious";

import { handlePlanConversion } from "analytics/googleTags";

interface IUpgradeAPIRes {
  proration_date: number;
  stripe_price_id: string;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CurrentPlan = () => {
  const { query } = useRouter();
  const { buyCredits } = query;
  const { triggerNotification } = useAppNotification();

  const plan = useSelector((state) => state.planSubscriptionReducer.plan);
  const credits = useSelector((state) => state.planSubscriptionReducer.credits);
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const subscriptionName = userDetail?.user.activeSubscription.name;
  const prevSubscriptionName = usePrevious(subscriptionName);

  useEffect(() => {
    if (subscriptionName && prevSubscriptionName) {
      if (prevSubscriptionName !== subscriptionName) {
        handlePlanConversion({
          planPrice: Number(userDetail.user.activeSubscription.cost),
        });
      }
    }
  }, [subscriptionName, prevSubscriptionName, userDetail]);

  const [planLoading, setPlanLoading] = useState<boolean>(false);
  const [creditsLoading, setCreditsLoading] = useState<boolean>(true);
  const [requiredPlan, setRequiredPlan] = useState<IPlan | undefined>(
    undefined
  );
  const [upgradePlanLoading, setUpgradePlanLoading] = useState<boolean>(false);
  const [confirmUpgradeLoading, setConfirmUpgradeLoading] =
    useState<boolean>(false);
  const [credit, setCredit] = useState<ICredit | undefined>(undefined);
  const onSetCredit = (credit: ICredit) => setCredit(credit);
  const [addCreditLoading, setAddCreditLoading] = useState<boolean>(false);
  const [cancelSubLoading, setCancelSubLoading] = useState<boolean>(false);
  const [upgradeApiRes, setUpgradeApiRes] = useState<
    IUpgradeAPIRes | undefined
  >(undefined);
  const [stripeLoading, setStripLoading] = useState<boolean>(false);

  const [duration, setDuration] = useState<EPlanDuration>("month");
  const toggleIsMonthly = () => {
    setDuration((prev) => {
      if (prev === "month") {
        return "year";
      } else {
        return "month";
      }
    });
  };

  const [buyCreditModal, setBuyCreditModal] = useState<boolean>(false);
  const toggleBuyCreditModal = () => {
    setCredit(undefined);
    setBuyCreditModal((prev) => {
      if (userDetail?.user.activeSubscription.name === "Free") {
        setNotifyModal({
          isOpen: true,
          isCancelSubscription: false,
          heading: "notify.modal.heading.buyCreditsWarning",
          message: "",
        });
        return false;
      } else {
        return !prev;
      }
    });
  };

  const [cancelPaymentModal, setCancelPaymentModal] = useState<boolean>(false);
  const toggleCancelPaymentModal = () => setCancelPaymentModal((prev) => !prev);

  const [invoiceModal, setInvoiceModal] = useState<boolean>(false);
  const toggleInvoiceModal = () => setInvoiceModal((prev) => !prev);

  const [notifyModal, setNotifyModal] = useState<{
    isCancelSubscription?: boolean;
    isOpen?: boolean;
    heading?: string;
    message?: string;
  }>({
    isCancelSubscription: false,
    isOpen: false,
    heading: "",
    message: "",
  });

  const onGetPlan = () => {
    setPlanLoading(true);
    getPlan()
      .then(() => {
        setPlanLoading(false);
      })
      .catch((err) => {
        setPlanLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  const onGetCredits = () => {
    setCreditsLoading(true);
    getCredits()
      .then(() => {
        setCreditsLoading(false);
      })
      .catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setCreditsLoading(false);
      });
  };

  useEffectOnce(() => {
    onGetCredits();
    getActivePlan().catch((err) => {
      triggerNotification({ message: err?.data?.message, type: "error" });
    });;
    onGetPlan();
  });

  useEffect(() => {
    if (buyCredits === "true") toggleBuyCreditModal();
  }, [buyCredits]);

  useEffect(() => {
    setDuration((userDetail?.user as IUser).activeSubscription.duration);
  }, [userDetail?.user.activeSubscription.duration]);

  const onUpgradePlan = (selectedPlan: IPlan) => {
    setUpgradePlanLoading(true);
    setRequiredPlan(selectedPlan);
    upgradePlan(selectedPlan.id)
      .then((res: any) => {
        if (res?.message) {
          setUpgradePlanLoading(false);
          triggerNotification({ message: res?.message, type: "info" });
        } else if (res?.url) {
          setUpgradePlanLoading(false);
          window.open(res?.url, "_self");
          ReactGA.event({
            action: "Subscription_PlanUpgrade_ButtonClicked",
            category: "WebsiteSettingCurrentPlanPageUpgradePlanBtn_category",
            label: `${userDetail?.user.activeSubscription.name}(${userDetail?.user.activeSubscription.duration}) to ${selectedPlan.name}(${duration})`,
          });
        } else {                 
          setUpgradePlanLoading(false);
          setUpgradeApiRes({
            proration_date: res?.proration_date,
            stripe_price_id: res?.stripe_price_id,
          });
          toggleInvoiceModal();
        }
      })
      .catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setRequiredPlan(undefined);
        setUpgradePlanLoading(false);
      });
  };

  const onConfirmUpgrade = () => {
    setConfirmUpgradeLoading(true);
    confirmUpgrade({
      proration_date: upgradeApiRes?.proration_date,
      stripe_price_id: upgradeApiRes?.stripe_price_id,
      plan_id: requiredPlan?.id,
    })
      .then(() => {
        setTimeout(() => {
          ReactGA.event({
            action: "Subscription_PlanUpgrade_ConfirmationButtonClicked",
            category: "WebsiteSettingCurrentPlanPageUpgradePlanBtn_category",
            label: `${userDetail?.user.activeSubscription.name}(${userDetail?.user.activeSubscription.duration}) to ${requiredPlan?.name}(${duration})`,
          });
          getActivePlan()
            .then(() => {
              setRequiredPlan(undefined);
              setUpgradeApiRes(undefined);
              setConfirmUpgradeLoading(false);
              toggleInvoiceModal();
              setNotifyModal({
                isOpen: true,
                isCancelSubscription: false,
                heading: "notify.modal.heading.success",
                message: "notify.modal.message.changeSubscription",
              });
            })
            .catch((err) => {
              setConfirmUpgradeLoading(false);
              triggerNotification({
                message: err?.data?.message,
                type: "error",
              });
            });
        }, 5000);
      })
      .catch((err) => {
        setConfirmUpgradeLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  const onAddCredits = () => {
    setAddCreditLoading(true);
    addCredits(credit?.id as number)
      .then((res: any) => {
        setAddCreditLoading(false);
        window.open(res?.url, "_self");
        toggleBuyCreditModal();
        ReactGA.event({
          action: "Credits_AddCredits_ButtonClicked",
          category: "WebsiteSettingCurrentPlanPageAddCreditBtn_category",
          label: "Credits",
          value: credit?.credits,
        });
      })
      .catch((err) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setAddCreditLoading(false);
      });
  };

  const onCancelSubscription = () => {
    setCancelSubLoading(true);
    cancelSubscription()
      .then(() => {
        ReactGA.event({
          action: "Subscription_CancelPlan_Action ",
          category: "WebsiteSettingCurrentPlanPageCancelPlanBtn_category",
          label: "Users",
        });
        getActivePlan()
          .then(() => {
            setCancelSubLoading(false);
            toggleCancelPaymentModal();
            setNotifyModal({
              isOpen: true,
              isCancelSubscription: true,
              heading: "notify.modal.heading.success",
              message: "notify.modal.message.cancelSubscription",
            });
          })
          .catch((err) => {
            setCancelSubLoading(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      })
      .catch((err) => {
        setCancelSubLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  const StripCustomerPortal = () => {
    setStripLoading(true);
    CustomerPortal()
      .then((resp) => {
        setStripLoading(false);
        window.open(resp as string, "_self");
      })
      .catch((err) => {
        setStripLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      })
  };

  return (
    <Container>
      {planLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Features
            userDetail={userDetail}
            duration={duration}
            toggleIsMonthly={toggleIsMonthly}
            toggleBuyCreditModal={toggleBuyCreditModal}
            StripCustomerPortal={StripCustomerPortal}
            stripeLoading={stripeLoading}
          />
          <PricingTable
            userDetail={userDetail}
            requiredPlan={requiredPlan}
            upgradePlanLoading={upgradePlanLoading}
            onUpgradePlan={onUpgradePlan}
            duration={duration}
            plan={plan}
            toggleCancelPaymentModal={toggleCancelPaymentModal}
          />
          {buyCreditModal && (
            <BuyCreditModal
              credit={credit}
              onSetCredit={onSetCredit}
              credits={credits}
              creditsLoading={creditsLoading}
              onCancel={toggleBuyCreditModal}
              onConfirm={onAddCredits}
              isConfirmLoading={addCreditLoading}
              onClose={toggleBuyCreditModal}
            />
          )}
          {cancelPaymentModal && (
            <CancelPaymentModal
              onClose={toggleCancelPaymentModal}
              onCancel={toggleCancelPaymentModal}
              onConfirm={onCancelSubscription}
              cancelSubLoading={cancelSubLoading}
            />
          )}
          {invoiceModal && (
            <InvoiceModal
              currentPlan={userDetail?.user.activeSubscription}
              requiredPlan={requiredPlan}
              onClose={toggleInvoiceModal}
              onCancel={toggleInvoiceModal}
              onConfirm={onConfirmUpgrade}
              isConfirmLoading={confirmUpgradeLoading}
            />
          )}
          {notifyModal.isOpen && (
            <SuccessModal
              heading={notifyModal.heading}
              message={notifyModal.message}
              onClose={() => {
                if (notifyModal.isCancelSubscription) {
                  window.open(
                    process.env.REACT_APP_CANCEL_SUBSCRIPTION_SURVEY_LINK,
                    "_blank"
                  );
                }
                setNotifyModal({
                  isOpen: false,
                  heading: "",
                  message: "",
                  isCancelSubscription: false,
                });
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};
