import { Fragment, RefObject, useCallback, useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./Header.module.scss";

import { RoutePaths } from "pages/routePaths";

import { LeftPanel } from "./LeftPanel";
import { ThemeSwitch } from "./ThemeSwitch";
import useRouter from "hooks/useRouter";

import { debounce } from "utils/functions";

import { useSelector } from "redux/hooks";
import { Logo, NavLinks, RightSection } from "./components";

interface ISubRoute {
  path: string;
  name: string;
  id: string;
  dataTestID: string;
}

export interface NavRoutesProps {
  path: string;
  name: string;
  id: string;
  dataTestID: string;
  subRoutes?: ISubRoute[];
}

export interface SectionRefs {
  feature: RefObject<HTMLElement>;
  price: RefObject<HTMLElement>;
  hero: RefObject<HTMLElement>;
  innovate: RefObject<HTMLElement>;
}

interface IProps {
  sectionRefs?: SectionRefs;
  activeSection?: string;
}

export const navRoutes: NavRoutesProps[] = [
  {
    path: "/#feature",
    id: "feature",
    name: "landing.nav.features",
    dataTestID: "feature",
    subRoutes: [
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.AccessToDiverseModels}`,
        name: "landing.nav.feature.models",
        id: "accessToDiverseModels",
        dataTestID: "feature-driverse-model",
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.ImageCreation}`,
        name: "landing.nav.feature.image",
        id: "imageCreation",
        dataTestID: "feature-image-creation",
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.DocumentBasedChat}`,
        name: "landing.nav.feature.document",
        id: "documentBasedChat",
        dataTestID: "feature-document-based-chat",
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.IntuitiveInterface}`,
        name: "landing.nav.feature.intuitive",
        id: "intuitiveInterface",
        dataTestID: "feature-intuitive-interface",
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.MultiLanguageCommunication}`,
        name: "landing.nav.feature.language",
        id: "multiLanguageCommunication",
        dataTestID: "feature-multi-language-communication",
      },
      {
        path: `/${RoutePaths.Feature}/${RoutePaths.ChromeExtension}`,
        name: "landing.nav.feature.extension",
        id: "chromeExtension",
        dataTestID: "feature-chrome-extension",
      },
    ],
  },
  {
    path: "/#pricing",
    id: "pricing",
    name: "landing.nav.pricing",
    dataTestID: "pricing",
  },
  {
    path: "/earn-as-affiliate",
    id: "affiliate",
    name: "landing.nav.affiliate",
    dataTestID: "earn-as-affiliate",
  },
  // {
  //   path: "/system_update",
  //   id: "system",
  //   name: "landing.nav.system_update",
  //   dataTestID:"system_update",
  // }
];

// Define a list of routes where the navigation menu should be hidden
// 定义一个需要隐藏导航菜单的路由列表
const excludedRoutes = [
  RoutePaths.Signup,
  RoutePaths.Login,
  RoutePaths.EmailSent,
  RoutePaths.VerifyEmail,
];

export const Header = ({
  sectionRefs,
  activeSection: defaultActiveSection,
}: IProps) => {
  const { includeRoute, pathname } = useRouter();
  const theme = useSelector((state) => state.authReducer.theme);
  const { userDetail } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  const [open, setOpen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<string | null>(null);

  // Determine if the current route is one of the excluded routes
  // 判断当前路由是否在排除列表中
  const shouldShowNavMenu = !excludedRoutes.some((route) =>
    includeRoute(route)
  );

  const handleScroll = useCallback(() => {
    if (pathname !== `${RoutePaths.Root}${lang}`) return;

    if (!sectionRefs) return;

    const { hero, feature, price } = sectionRefs;

    const sections = [
      { id: "hero", ref: hero },
      { id: "feature", ref: feature },
      { id: "pricing", ref: price },
    ];

    for (const section of sections) {
      const rect = section.ref.current?.getBoundingClientRect();
      if (rect && rect.top <= 100 && rect.bottom >= 100) {
        if (section.id === "hero") {
          setActiveSection(null);
          window.history.replaceState(`/${lang}`, "", `/${lang}`);
        } else {
          setActiveSection(section.id);
          window.history.replaceState("/", "", `/${lang}/#${section.id}`);
        }
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (defaultActiveSection) setActiveSection(defaultActiveSection);
  }, [defaultActiveSection]);

  useEffect(() => {
    const debouncedFunction = debounce(handleScroll, 60);
    window.addEventListener("scroll", debouncedFunction);
    return () => window.removeEventListener("scroll", debouncedFunction);
  }, [handleScroll]);

  // const isLanding = pathname === `/${lang}/` ||  pathname ===  `/${lang}` ;

  return (
    <>
      {open && (
        <LeftPanel
          activeSection={activeSection}
          onClose={() => setOpen(false)}
        />
      )}
      <header
        className={classNames(styles.header, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.container}>
          <div className={classNames(styles.headerBody,{
            [styles.shouldShowNavMenu]: shouldShowNavMenu,
          })}>
            <Logo
              lang={lang}
              onClick={() => setOpen((prev) => !prev)}
            />
            {shouldShowNavMenu ? (
              <Fragment>
                <NavLinks
                  navRoutes={navRoutes}
                  userDetail={userDetail}
                  theme={theme}
                  activeSection={activeSection}
                  lang={lang}
                />
                <RightSection
                  lang={lang}
                />
              </Fragment>
            ) : (
              <ThemeSwitch/>
            )}
          </div>
        </div>
      </header>
    </>
  );
};
