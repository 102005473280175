import { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styled from "styled-components";
import styles from "./Footer.module.scss";

import {
  DiscordIcon,
  TwitterIcon,
  AkaiIcon,
  YoutubeIcon,
  TikTok,
  Pinterest,
  Linkedin,
  facebook,
} from "./Icons";

import { DropDown, DropDownRow } from "../base";

import { languages } from "utils/languages";

import { updateLanguage } from "redux/actions";
import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";
import { Link } from "react-router-dom";
import { FooterLogo } from "components/Logo/FooterLogo";
import { useWindowSize } from "hooks/useWindowSize";

const Container = styled.div<{
  themeMode?: EThemeType;
  landingFooter?:boolean;
}>`
  background-color: ${({ themeMode, landingFooter }) =>
    landingFooter
      ? themeMode === "dark"
        ? "rgba(42, 40, 49, 0.80)"
        : "rgba(255, 255, 255, 0.30)"
      : ""};
  width: 100%;
  @media screen and (max-width: 576px) {
    background-color: ${({ themeMode }) =>
    themeMode === "dark" ? "#131314" : "#fff"};
    width: 100%;
  }
`;

const FooterBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  @media screen and (min-width: 1120px) {
    flex-direction: row;
    padding: 20px 146px;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 1120px) {
    padding: 20px 140px;
  }

  @media screen and (max-width: 900px) {
    padding: 20px 35px;
  }
  @media screen and (max-width: 799px) {
    padding: 20px 15px;
  }
  @media screen and (max-width: 576px) {
    padding: 20px 25px;
  }
  @media screen and (max-width: 380px) {
    padding: 20px 15px;
  }
`;

const SelectBottom = styled.div`
  position: relative;
  display: none;

  @media screen and (min-width: 1120px) {
    display: flex;
  }
`;

const SmallSelectBottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1120px) {
    display: none;
    // display: flex;
  }
`;

interface FooterMenuItem {
  id: string;
  href: string;
  blank?: boolean;
  lang?: boolean;
  dataTestId: string;
}

interface SocialIcon {
  href: string;
  blank?: boolean;
  Icon: any;
  dataTestId: string;
}

const socialIcons: SocialIcon[] = [
  {
    href: "https://www.linkedin.com/showcase/deftgpt",
    blank: true,
    Icon: Linkedin,
    dataTestId: "deftgpt-linkedin",
  },
  {
    href: "https://www.pinterest.com/DeftGPT/",
    blank: true,
    Icon: Pinterest,
    dataTestId: "deftgpt-pinterest",
  },
  {
    href: "https://www.tiktok.com/@deftgpt",
    blank: true,
    Icon: TikTok,
    dataTestId: "deftgpt-tiktok",
  },
  {
    href: "https://www.facebook.com/DeftGPT",
    blank: true,
    Icon: facebook,
    dataTestId: "deftgpt-facebook",
  },
  {
    href: "https://discord.com/",
    blank: true,
    Icon: DiscordIcon,
    dataTestId: "deftgpt-discord",
  },
  {
    href: "https://twitter.com/DeftGPT",
    blank: true,
    Icon: TwitterIcon,
    dataTestId: "deftgpt-twitter",
  },
  {
    href: " https://www.instagram.com/official.deftgpt/",
    blank: true,
    Icon: AkaiIcon,
    dataTestId: "deftgpt-instagram",
  },
  {
    href: "https://www.youtube.com/@DeftGPT",
    blank: true,
    Icon: YoutubeIcon,
    dataTestId: "deftgpt-youtube",
  },
];

const menuItems: FooterMenuItem[] = [
  {
    id: "landing.footer.support",
    href: "https://deftgpt.canny.io/feature-requests",
    blank: true,
    lang: false,
    dataTestId: "landing-footer-support",
  },
  {
    id: "landing.footer.contact",
    href: "mailto:support@deftgpt.com",
    blank: false,
    lang: false,
    dataTestId: "landing-footer-contact",
  },
  // {
  //   id: "landing.footer.affiliate",
  //   href: "/earn-as-affiliate",
  //   blank: false,
  //   lang: true,
  //   dataTestId: 'landing-footer-affiliate'
  // },
  {
    id: "landing.footer.privacy",
    href: "/privacy",
    blank: false,
    lang: true,
    dataTestId: "landing-footer-privacy",
  },
  {
    id: "landing.footer.terms",
    href: "/terms",
    blank: false,
    lang: true,
    dataTestId: "landing-footer-terms",
  },
  {
    id: "landing.footer.submitFeedback",
    href: "https://deftgpt.canny.io/feature-requests",
    blank: true,
    lang: false,
    dataTestId: "landing-footer-submitFeedback",
  },
];


export const Footer = (
  {isHasBgOnFooter,landingFooter}: {isHasBgOnFooter ? : boolean, landingFooter?:boolean}

) => {
  const [language, setLanguage] = useState<string | undefined>(
    languages[0].value
  );

  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  useEffect(
    () => setLanguage(userDetail?.user.language || languages[0].value),
    [userDetail]
  );

  const onUpdateLanguage = useCallback((lang: string) => {
    setLanguage(lang);
    updateLanguage(lang);
  }, []);

  const {width} = useWindowSize();

  const style = isHasBgOnFooter
    ? {
      backgroundImage: `url(/landing/${theme}/footer.png)`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }
    : undefined;

  return (
    <Container themeMode={theme}
     style={style}
     landingFooter={landingFooter}
     >
      <FooterBody>
          <div className="flex flex-row items-center justify-between">
            <div className={styles.logoContainer}>
              <FooterLogo/>
              <span className={styles.logoHead}>DeftGPT</span>
            </div>
            <SmallSelectBottom>
              <DropDown
                small={true}
                variant={theme}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    width:"130px",
                    marginTop:"-10px",
                    // TODO: temporary dirty hack
                    transform: "translate3D(0px, -344px, 0px)",
                  }),
                }}
                height={44}
                options={languages}
                value={language}
                onChange={(item: DropDownRow.IDataItem) =>
                  onUpdateLanguage(item.value)
                }
                className={styles.select}
              />
            <div className={styles.social__container} >
            {socialIcons.map((social, key) => {
              const Icon = social.Icon;
              return (
                <a
                  key={key}
                  href={social.href}
                  target={social.blank ? "_blank" : "_self"}
                  className={styles.socialBtn}
                  rel="noreferrer"
                >
                  <span
                    className={classNames(styles.icon, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <Icon />
                  </span>
                </a>
              );
            })}
          </div>
            </SmallSelectBottom>
          </div>
        <nav className={styles.menu}>
          <ul className={styles.list}>
            {menuItems.map((menu) => (
              <li key={menu.id} className={styles.listl}>
                {menu.id === "landing.footer.affiliate" ? (
                  <Link
                    to={
                      !userDetail?.token && menu.lang
                        ? `/${lang}${menu.href}`
                        : menu.href
                    }
                    target={menu.blank ? "_blank" : "_self"}
                    className={classNames(styles.link, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    data-testid={menu.dataTestId}
                  >
                    <FormattedMessage id={menu.id} />
                  </Link>
                ) : (
                  <a
                    href={menu.lang ? `/${lang}${menu.href}` : menu.href}
                    target={menu.blank ? "_blank" : "_self"}
                    className={classNames(styles.link, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                    rel="noreferrer"
                    data-testid={menu.dataTestId}
                  >
                    <FormattedMessage id={menu.id} />
                  </a>
                )}
              </li>
            ))}
          </ul>
          <p className="text-light dark:text-[#fff] font-bold text-[14px] w-full text-center capitalize mt-5 sm:mt-[6px]">
            <FormattedMessage id="landing.footer.all.rights" />
          </p>
        </nav>
        <div className="flex flex-col">
          <div className={styles.smallscreen}>
            <SelectBottom>
              <DropDown
                small={true}
                variant={theme}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    width:"130px",
                    marginTop:"-10px",
                    // TODO: temporary dirty hack
                    transform: "translate3D(0px, -344px, 0px)",
                  }),
                }}
                height={44}
                options={languages}
                value={language}
                onChange={(item: DropDownRow.IDataItem) =>
                  onUpdateLanguage(item.value)
                }
              />
            </SelectBottom>
          </div>
          <div className={styles.social__container} style={{display: width < 1120 ? 'none': 'flex'}}>
            {socialIcons.map((social, key) => {
              const Icon = social.Icon;
              return (
                <a
                  key={key}
                  href={social.href}
                  target={social.blank ? "_blank" : "_self"}
                  className={styles.socialBtn}
                  rel="noreferrer"
                >
                  <span
                    className={classNames(styles.icon, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}
                  >
                    <Icon />
                  </span>
                </a>
              );
            })}
          </div>
        </div>
      </FooterBody>
    </Container>
  );
};
