import { useSidebar } from 'hooks/services/ReSizeSidebar/ReSizeSidebar';
import React, { useState } from 'react';
import SplitPane, { SplitPaneProps } from 'react-split-pane';
import styles from '../MainView/MainView.module.scss';
import { useWindowSize } from 'hooks/useWindowSize';

interface CustomSplitPaneProps extends SplitPaneProps {
  children: React.ReactNode;
}

export const CustomSplitPane: React.FC<CustomSplitPaneProps> = (props) => {

  const { sidebarWidth } = useSidebar();
  const { width } = useWindowSize();
  const [isDragging, setIsDragging] = useState(false);

  return <SplitPane {...props}
    resizerStyle={{
      contain: 'strict',
      position: 'absolute',
      left: `${sidebarWidth - 5}px`,
      display: width <=768 ? 'none' : "block",

    }}
    resizerClassName={`${styles.customResizer} ${isDragging ? styles.customResizerHover : ''}`} // Apply custom resizer class
    onDragStarted={() => setIsDragging(true)}      // Start highlighting on drag
    onDragFinished={() => setIsDragging(false)}
    style={{ height: '100vh' }} />;
};