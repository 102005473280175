import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { InputHTMLAttributes, useCallback, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useToggle } from "react-use";

import Button from "../Button/Button";
import styles from "./Input.module.scss";
import { EThemeType } from "redux/reducers";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  focusedStyle?: boolean;
  grey?: boolean;
  variant?: EThemeType;
  prompt?: boolean;
  inputSpace?:boolean;
  updateEmail?:boolean;
}

export const Input = ({
  error,
  grey,
  focusedStyle,
  variant,
  prompt,
  inputSpace,
  updateEmail,
  ...props
}: InputProps) => {
  const { formatMessage } = useIntl();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const inputSelectionStartRef = useRef<number | null>(null);

  const [isContentVisible, toggleIsContentVisible] = useToggle(false);
  const [focused, setFocused] = useState(false);

  const isPassword = props.type === "password";
  const isVisibilityButtonVisible = isPassword && !props.disabled;
  const type = isPassword
    ? isContentVisible
      ? "text"
      : "password"
    : props.type;

  const focusOnInputElement = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    const { current: element } = inputRef;
    const selectionStart =
      inputSelectionStartRef.current ?? inputRef.current?.value.length;

    element.focus();

    if (selectionStart) {
      window.setTimeout(() => {
        element.setSelectionRange(selectionStart, selectionStart);
      }, 0);
    }
  }, []);

  const handleTrimmedInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(inputSpace){
    const trimmedValue = e.target.value.trim();
    if (inputRef.current) {
      inputRef.current.value = trimmedValue;
    }
  }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const onContainerFocus: React.FocusEventHandler<HTMLDivElement> = () => {
    setFocused(true);
  };

  const onContainerBlur: React.FocusEventHandler<HTMLDivElement> = (event) => {
    if (isVisibilityButtonVisible && event.target === inputRef.current) {
      inputSelectionStartRef.current = inputRef.current.selectionStart;
    }

    setFocused(false);

    if (isPassword) {
      window.setTimeout(() => {
        if (
          document.activeElement !== inputRef.current &&
          document.activeElement !== buttonRef.current
        ) {
          toggleIsContentVisible(false);
          inputSelectionStartRef.current = null;
        }
      }, 0);
    }
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.disabled]: props.disabled,
        [styles.focused]: focused && focusedStyle && !updateEmail,
        [styles.error]: error,
        [styles.grey]: grey,
        [styles.lightMode]: variant === "light",
        [styles.promptLightMode]: variant==='light' && prompt,
        [styles.darkMode]: variant === "dark",
        [styles.updateEmail]: updateEmail,
      })}
      data-testid="input-container"
      onFocus={onContainerFocus}
      onBlur={onContainerBlur}
    >
      <input
        data-testid="input"
        {...props}
        ref={inputRef}
        type={type}
        className={classNames(
          styles.input,
          {
            [styles.disabled]: props.disabled,
            [styles.password]: isPassword,
            [styles.lightMode]: variant === "light",
            [styles.darkMode]: variant === "dark",
            [styles.promptLightMode]: variant==='light' && prompt,
            [styles.updateEmail]: updateEmail,
        [styles.updateEmailLight]: variant==='light' &&  updateEmail ,
          },
          props.className
        )}
        onChange={ handleTrimmedInputChange}
      />
      {isVisibilityButtonVisible ? (
        <Button
          className={styles.visibilityButton}
          ref={buttonRef}
          iconOnly
          onClick={() => {
            toggleIsContentVisible();
            focusOnInputElement();
          }}
          type="button"
          aria-label={formatMessage({
            id: `ui.input.${isContentVisible ? "hide" : "show"}Password`,
          })}
          data-testid="toggle-password-visibility-button"
        >
          <FontAwesomeIcon
            icon={isContentVisible ? faEyeSlash : faEye}
            fixedWidth
          />
        </Button>
      ) : null}
    </div>
  );
};

export default Input;
