import { Tooltip } from "components";
import { FormattedMessage } from "react-intl";

import {
  ShareIcon,
} from "../icons";

import { IChat, IChatModel, IMessage, IShareChatMessage } from "redux/actions";

import classNames from "classnames";
import { useSelector } from "redux/hooks";
import styles from "../messageContainer/answerContainer.module.scss";
import { RegenerateImage } from "../regeneratewithModel/regenerateImage";

interface IProps {
  share?: boolean;
  shareChat?: boolean;
  shareMessage?: IShareChatMessage;
  messageIndex?: number;
  message?: IMessage;
  toggleShareChat?: () => void;
  handleCheckboxChange?: (messageId: any) => void;
  onRegenerate?: (messageIndex: number, chatModal: IChatModel, regenerateModel?: boolean) => void;
  chatItem?: IChat;
}

export const AssistantMessageAction = ({
  messageIndex,
  message,
  share,
  onRegenerate,
  toggleShareChat,
  handleCheckboxChange,
  chatItem,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { PrivateChat } = useSelector((state) => state.chatReducer);

  return (
    <div className={styles.actionContainer}>
      {/* REGENERATE BUTTON */}
      <div className="flex flex-row items-center mt-1">
        {(!share && PrivateChat === false) && (
          <>
            <Tooltip
              regenrate={true}
              tooltipAdjustement={true}
              control={
                <div
                  className={classNames(styles.icon, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.iconContainer]: true
                  })}
                  onClick={() => {
                    toggleShareChat!();
                    handleCheckboxChange!(message?.id);
                  }}
                  data-testid="share-chat-btn"
                >
                  <ShareIcon theme={theme} />
                </div>
              }
              placement="top"
              theme="light"
            >
              <div>
                <FormattedMessage id="share.tooltip.chat" />
              </div>
            </Tooltip>
          </>
        )}

        <RegenerateImage
          message={message}
          chatItem={chatItem}
          messageIndex={messageIndex}
          onRegenerate={onRegenerate}
        />
      </div>
    </div>
  );
};
