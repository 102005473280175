import _ from "lodash";
import { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";

import classNames from "classnames";
import styles from "./GPTModal.module.scss";

import { Modal } from "components";
// import Button from "components/Button";
import { ModalItem } from "./ModalItem/ModalItem";
import { isAndroid } from "react-device-detect";
import useRouter from "hooks/useRouter";
import { ChatRoute, IUploadFile } from "pages/ChatPage";

import { ChatType, IChatModel, setChatModel } from "redux/actions";
import { useSelector } from "redux/hooks";
import { useLocation } from 'react-router-dom';
import { SearchField } from "views/layout/Sidebar/component/ChatSidebar/components/SearchField";
import { FormattedMessage } from "react-intl";
import { Spinner } from "components";

interface IProps {
  onClose?: () => void;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  docuemntModel?: boolean;
  isMainScreenOpen?: boolean;
  isFileUploading?: boolean;
  loadModel?: boolean;
  uploadingFiles?: IUploadFile[];
}

export const GPTModal = ({ onClose,
  // onStartNewChat,
  docuemntModel,
  isMainScreenOpen,
  isFileUploading,
  loadModel,
  uploadingFiles,
}: IProps) => {

  const { includeRoute, pathname } = useRouter();
  const location = useLocation();
  const { gptModel, theme } = useSelector((state) => state.authReducer);
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { newMessages, messages } = useSelector((state) => state.chatReducer);
  const history = pathname.includes("/chat/history");
  const chatId = pathname.split("/")[3];

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [tab, setTab] = useState<"text" | "image">("text");
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isPositionTop, setIsPositionTop] = useState<boolean>(true);

  const [selectedModal, setSelectedModal] = useState<IChatModel>(
    gptModel as IChatModel
  );
  const [filteredModels, setFilteredModel] = useState<IChatModel[]>([]);
  const [filteredSearchModels, setFilteredSearchModel] = useState<IChatModel[]>([]);
  const onSelectModal = (modal: IChatModel) => {
    setSelectedModal(modal);
    ReactGA.event({
      action: "Popup_ModelSelection_Displayed",
      category: "WebsiteModelSelectionPopUpShown",
    });
    setChatModel(modal);
    if (gptModel?.type?.includes(ChatType.text)) {
      localStorage.setItem('GptModel', JSON.stringify(modal));

      const firstUploadingFileType = uploadingFiles?.[0]?.file.type;
      const firstNewMessage = newMessages?.[0];
      const firstMessage = messages?.[0];

      if (firstUploadingFileType?.startsWith('image/')) {
        localStorage.setItem('imageChatGptModel', JSON.stringify(modal));
      } else if (firstUploadingFileType && !firstUploadingFileType.startsWith('image/')) {
        localStorage.setItem('documentChatGptModel', JSON.stringify(modal));
      } else if (firstNewMessage?.images?.length > 0 || (firstMessage?.images?.length > 0 && history)) {
        localStorage.setItem('imageChatGptModel', JSON.stringify(modal));
      } else if ((firstNewMessage?.files?.length ?? 0) > 0 || ((firstMessage?.files?.length ?? 0) > 0 && history)) {
        localStorage.setItem('documentChatGptModel', JSON.stringify(modal));
      }
    } else {
      localStorage.setItem('imageGptModel', JSON.stringify(modal));
    }
    onClose?.();
  }

  useEffect(() => {
    if (gptModel?.type?.includes(ChatType.image) && !gptModel?.type?.includes(ChatType.image_chat)) setTab("image");
    else setTab("text");
  }, [gptModel]);

  useEffect(() => {
    const newFilteredUserPrompts = filteredModels.filter((model) => {
      return (model.name.toLowerCase().includes(searchQuery.toLowerCase()) && model?.type?.includes(tab) ||
        model.name.includes(searchQuery) && model?.type?.includes(tab));
    });
    setFilteredSearchModel(newFilteredUserPrompts)
  }, [searchQuery]);

  useEffect(() => {
    let filteredModels = chatModels;

    const shouldFilterByDocument =
      ((messages[0]?.files || (messages[0]?.images && messages[0]?.images.length > 0)) || location.pathname === '/chat/new') &&
      gptModel?.type?.includes(ChatType.text) &&
      !_.isEqual(gptModel?.type, ["text"]) &&
      (gptModel?.type?.includes(ChatType.text) || gptModel?.type?.includes(ChatType.document))
      && docuemntModel;

    if (shouldFilterByDocument) {

      if (newMessages.length > 0 && !newMessages[0]?.files && (newMessages[0]?.images && newMessages[0]?.images.length === 0) && ((uploadingFiles && uploadingFiles.length === 0)) && !isFileUploading)
        filteredModels = chatModels;
      else if ((isMainScreenOpen && !newMessages[0]?.files && !includeRoute(ChatRoute.History) && (uploadingFiles === null || uploadingFiles?.length === 0) && !isFileUploading))
        filteredModels = chatModels;
      else if (((uploadingFiles && uploadingFiles[0]?.file.type.includes('image/')) || ((newMessages[0]?.images && newMessages[0]?.images.length > 0) || (messages[0]?.images && messages[0]?.images.length > 0 && messages[0]?.chat_id === Number(chatId)))))
        filteredModels = chatModels.filter(model => model.type.includes(ChatType.image_chat) && model.type.includes('document'));
      else
        filteredModels = chatModels.filter(model => model.type.includes('document'));
    }
    setFilteredModel(filteredModels);

  }, [chatModels, searchQuery === '',]);

  const onClearSearchField = () => {
    setSearchQuery("");
    if (inputRef.current) {
      inputRef.current.focus();
      setIsPositionTop(true);
    }

  };

  const handleSerchFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      setIsPositionTop(true);
    }
  }

  return (
    <Modal size="sm" positionTop={isAndroid ? isPositionTop : false} onClose={onClose}>
      <div className="px-[22px] pt-[7px]">
        <SearchField
          inputRef={inputRef}
          value={searchQuery}
          gptModal={true}
          onChange={(e) => setSearchQuery(e.target.value)}
          onClear={onClearSearchField}
          setIsPositionTop={setIsPositionTop}
          isPositionTop={isPositionTop}
          handleSerchFocus={handleSerchFocus}
          autoFocus
        />
      </div>
      <div className={styles.body}>
        {chatModels.length > 0 ? (
          searchQuery !== '' && filteredSearchModels.length > 0 ?
            filteredSearchModels.filter((model) => model?.type?.includes(tab))
              .map((model) => (
                <ModalItem
                  chatModel={model}
                  selectedModal={selectedModal}
                  onSelectModal={onSelectModal}
                />
              ))
            : searchQuery !== '' && filteredSearchModels.length === 0 ? (
              <div className={classNames(styles.message, {
                [styles.light]: theme === 'light',
              })}><FormattedMessage id="gptmodel.no.available" /></div>
            ) :
              filteredModels
                .filter((model) => model?.type?.includes(tab))
                .map((model) => (
                  <ModalItem
                    chatModel={model}
                    selectedModal={selectedModal}
                    onSelectModal={onSelectModal}
                  />
                ))
        ) : (
          loadModel ? (
            <div className='flex item-centers justify-center mb-2'>
              <Spinner small />
            </div>
          ) : (
            <div className={classNames(styles.message, {
              [styles.light]: theme === 'light',
            })}><FormattedMessage id="gptmodel.no.available" /></div>
          )
        )}
      </div>
    </Modal>
  );
};
