import { useCallback } from "react";
import { useEffectOnce } from "react-use";

import { AuthContainer } from "../components/AuthContainer";
import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

// import { verifyEmail, getCreditLimits } from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import { useSelector } from "redux/hooks";
import { VerifyChangeEmail } from "redux/actions";


export const EmailChangeVerification = () => {
  const { params, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const {userDetail} = useSelector((state)=> state.authReducer);
  const lang = userDetail?.user?.language? userDetail?.user?.language: "en";
  const onVerifyEmail = useCallback(() => {
    const { token } = params;
    VerifyChangeEmail(token)
      .then(() => {
        setTimeout(()=> push(`/${RoutePaths.Settings}`) ,500) ;
      })
      .catch((err: any) => {
        push(`/${lang}/${RoutePaths.Login}`);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }, [params, push, triggerNotification]);

  useEffectOnce(() => onVerifyEmail());

  return (
    <AuthContainer>
      <LoadingPage />
    </AuthContainer>
  );
};
