import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Field, Formik, FieldProps } from "formik";

import styles from "./EditAccount.module.scss";
import styled from "styled-components";

import { CloseIcon } from "components/icons/CloseIcon";

import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import {
  LabeledInput,
  Modal,
} from "components";
import CustomButton from "components/Button";

import { IEditEmail } from "redux/actions";
import { useSelector } from "redux/hooks";
import { UpdateEmailIcon } from "../icons/UpdateEmailIcon";
import { useWindowSize } from "hooks/useWindowSize";
import classNames from "classnames";
interface IProps {
  onClose?: () => void;
  onSubmit?: (data: IEditEmail) => void;
  loading?: boolean;
  isConfirmationModalVisible?: boolean;
}

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
    @media (max-width: 576px) {
          display: flex;
          flex-direction:column;
          gap:10px;
        }
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 5px;
  margin-bottom: 0;
  padding-bottom: 0;
`;

const Footer = styled.div<{ marginTop?: string }>`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  flex-shrink: 0;
  margin-top: ${(props) => props.marginTop || "0"};
  font-size:16px;
    @media (max-width: 576px) {
          font-size:12px;
        }
`;

export const EditAccount = ({
  onClose,
  onSubmit,
  loading,
  isConfirmationModalVisible,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();

  const AddMemberValidationSchema = yup.object().shape({
    email: yup
      .string()
      .email("modal.inviteTeam.email.error")
      .required("modal.inviteTeam.email.empty.error"),
  });

  const email = localStorage.getItem("email");

  return (
    <>
      <Modal onClose={onClose} editEmail updateEmail>
        <div className={styles.header}>
          <div className={styles.headerTextContainer}>
            <div className={styles.title}>
              <FormattedMessage id="modal.editAccount.heading" />
            </div>
          </div>
          <div
            className={styles.crossBtn}
            onClick={onClose}
            data-testid="team-form-cross-btn"
          >
            <CloseIcon />
          </div>
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={AddMemberValidationSchema}
          validateOnBlur={false} 
          onSubmit={(values) => {
            localStorage.setItem("email", values.email);
            onSubmit?.({
              email: values.email,
            });
          }}
        >
          {({isValid, dirty }) => (
            <div className="w-[100%]">
              <Form data-testid="account-updateEmail-form">
                <FieldsRow>
                  <FieldRowItem>
                    <Field name="email">
                      {({ field}: FieldProps<string>) => (
                        <LabeledInput
                          updateEmail={true}
                          {...field}
                          placeholder={formatMessage({
                            id: "Email.verification.placeholder",
                          })}
                          type="email"
                          variant={theme}
                          data-testid="account-updateEmail-email"
                        />
                      )}
                    </Field>
                  </FieldRowItem>

                  <Footer  >
                    <CustomButton
                      style={{
                        padding: width <= 576 ? "8px 12px 8px 12px" : "10px 16px 10px 14px",
                        borderRadius: "5px",
                        background: (!isValid || !dirty) ? "#DBDBDB" :
                          "var(--btn-color, linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%))",
                        fontSize: width <= 576 ? "12px" : "16px",
                        cursor:(!isValid || !dirty) ?"not-allowed":"",
                      }}
                      type="submit"
                      isloading={loading}
                      disabled={!isValid || !dirty}
                      data-testid="account-btn-updateEmail"
                    >
                      <FormattedMessage id="Email.verify.submit" />
                    </CustomButton>
                  </Footer>
                </FieldsRow>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>

      {isConfirmationModalVisible && (
        <Modal editConfirmationEmail onClose={onClose} updateEmail>
          <div className={styles.header}>
            <div className={styles.headerTextContainer}></div>
            <div
              className={styles.crossBtn}
              onClick={onClose}
              data-testid="updateemail-cross-btn"
            >
              <CloseIcon />
            </div>
          </div>
          <div className={styles.emailConfirmation}>
            <UpdateEmailIcon />
            <div className={classNames(styles.confirmationTitle, {
              [styles.dark]: theme === 'dark',
              [styles.light]: theme === 'light',
            })}>
              <FormattedMessage id="modal.updateEmail.heading" />
            </div>
            <p className={classNames(styles.confirmationText, {
              [styles.dark]: theme === 'dark',
              [styles.light]: theme === 'light',
            })}>
              <FormattedMessage
                id="updateEmailMessage1"
                values={{
                  email: (
                    <span className={classNames(styles.highlightedEmail, {
                      [styles.dark]: theme === 'dark',
                      [styles.light]: theme === 'light',
                    })}>{email}</span>
                  ),
                }}
              />{" "}
              <FormattedMessage id="updateEmailMessage" />
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};
