export const UpdateEmailIcon = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="187" height="189" viewBox="0 0 187 189" fill="none">
<mask id="mask0_21346_48150" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="18" y="70" width="150" height="45">
<path d="M18.0481 70.0547H167.898V114.948H18.0481V70.0547Z" fill="white"/>
</mask>
<g mask="url(#mask0_21346_48150)">
<path d="M167.894 70.5391H18.052L92.9693 114.571L167.894 70.5391Z" fill="#5B71F5"/>
</g>
<mask id="mask1_21346_48150" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="18" y="26" width="150" height="45">
<path d="M18.0481 26.5078H167.898V70.5475H18.0481V26.5078Z" fill="white"/>
</mask>
<g mask="url(#mask1_21346_48150)">
<path d="M18.052 70.5378H167.894L92.9693 26.5039L18.052 70.5378Z" fill="#5B71F5"/>
</g>
<path d="M143.742 146.833H43.5317V49.6602H143.742V146.833Z" fill="#F2F4FF"/>
<path d="M111.799 75.0966H75.4849V70.5352H111.799V75.0966Z" fill="#5B71F5"/>
<path d="M125.215 83.3485H62.0645V78.7891H125.215V83.3485Z" fill="#5B71F5"/>
<path d="M125.215 91.8251H62.0645V87.2617H125.215V91.8251Z" fill="#5B71F5"/>
<path d="M125.215 101.491H62.0645V96.9258H125.215V101.491Z" fill="#5B71F5"/>
<mask id="mask2_21346_48150" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="18" y="70" width="76" height="89">
<path d="M18.0481 70.0547H93.2467V158.598H18.0481V70.0547Z" fill="white"/>
</mask>
<g mask="url(#mask2_21346_48150)">
<path d="M18.052 70.5352V158.591L92.9693 114.569L18.052 70.5352Z" fill="#5B71F5"/>
</g>
<mask id="mask3_21346_48150" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="92" y="70" width="76" height="89">
<path d="M92.7534 70.0547H167.898V158.598H92.7534V70.0547Z" fill="white"/>
</mask>
<g mask="url(#mask3_21346_48150)">
<path d="M167.894 158.593V70.5391L92.9692 114.571L167.894 158.593Z" fill="#5B71F5"/>
</g>
<mask id="mask4_21346_48150" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="18" y="111" width="150" height="48">
<path d="M18.0481 111.988H167.898V158.599H18.0481V111.988Z" fill="white"/>
</mask>
<g mask="url(#mask4_21346_48150)">
<path d="M95.4283 112.238H90.5142L18.052 158.594H167.894L95.4283 112.238Z" fill="#A1AEFF"/>
</g>
<rect x="0.5" y="173" width="111" height="4" fill="#D9D9D9"/>
<rect x="94.5" y="185" width="71" height="4" fill="#D9D9D9"/>
<rect x="120.5" y="173" width="20" height="4" fill="#D9D9D9"/>
<rect x="148.5" y="173" width="38" height="4" fill="#D9D9D9"/>
</svg>
    )
}