import React from "react";
import styles from "../../chatFooter.module.scss";
import classNames from "classnames";
import { useSelector } from "redux/hooks";
import { FormattedMessage } from "react-intl";
import { ChatType, IChatModel, setImageAttribute, setRegenerateImageAttribute } from "redux/actions";
import { CountDD } from "../CountDD";
import { SizeDD } from "../SizeDD";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { ImageCountOptions } from "utils/constants";
import imgstyles from "./imageGeneration.module.scss";

interface FilePreviewProps {
  getMessagesLoading?: boolean | undefined;
  regenerateImage?: boolean;
  selectedModel?: IChatModel;
}

export const ImageGenerationOptions: React.FC<FilePreviewProps> = ({
  getMessagesLoading,
  regenerateImage,
  selectedModel,
}) => {
  const { theme, gptModel, imageAttributes, regenerateAttribute } = useSelector(
    (state) => state.authReducer
  );
  const { isOpen } = useToggleSidebar();

  return (
    <>
      {
        gptModel?.type?.includes(ChatType.image) &&
        !gptModel?.type?.includes(ChatType.image_chat) && (
          <div
            className={classNames(styles.IGOptionsContainer, imgstyles.IGOptionsContainer, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
              [styles.isSidebarOpen]: isOpen,
              [styles.isbackdrop]: getMessagesLoading,
              [imgstyles.light]: theme === "light" && regenerateImage,
              [imgstyles.dark]: theme === "dark" && regenerateImage,
            })}
          >
            <div className={classNames(styles.IGOptions,{
              [styles.isSidebarOpen]: isOpen,
              [styles.light]: theme === "light" && isOpen,
              [styles.dark]: theme === "dark" && isOpen,
            })}>
              <div className={classNames(styles.option, {
                [imgstyles.regenerateOptions]: regenerateImage,
                [styles.dark]: theme === 'dark',
                [styles.isSidebarOpen]: isOpen,
                [styles.imagePadding]:true,
              })}>
                <div className={styles.label}>
                  <FormattedMessage id="imageGeneration.chatFooter.dropdown.label.imageCount" />
                </div>
                <CountDD
                  selectedOption={regenerateImage ? Number(regenerateAttribute?.count) : Number(imageAttributes?.count)}
                  options={ImageCountOptions}
                  maxCount={regenerateImage && selectedModel?.attributes ? selectedModel?.attributes?.max_image_count?? regenerateAttribute?.maxCount?? imageAttributes?.maxCount : imageAttributes?.maxCount}
                  onSelect={(option) => {
                    if (regenerateImage)
                      setRegenerateImageAttribute({
                        key: "count",
                        value: option.value,
                      })
                    else
                      setImageAttribute({
                        key: "count",
                        value: option.value,
                      })
                  }
                  }
                  dataTestId="chat-footer-count"
                  regenerateImage={regenerateImage}
                />
              </div>

              <div  className={classNames(classNames(styles.option,{
                [styles.isSidebarOpen]: isOpen,
              }), {
                [imgstyles.regenerateOptions]: regenerateImage,
              })}>
                <div className={styles.label}>
                  <FormattedMessage id="imageGeneration.chatFooter.dropdown.label.size" />
                </div>
                <SizeDD
                  selectedOption={regenerateImage ? regenerateAttribute?.size?? imageAttributes?.size : imageAttributes?.size}
                  options={regenerateImage ? selectedModel?.attributes?.sizes ?? gptModel?.attributes?.sizes : gptModel?.attributes?.sizes}
                  onSelect={(option) => {
                    if (regenerateImage)
                      setRegenerateImageAttribute({
                        key: "size",
                        value: option,
                      })
                    else
                      setImageAttribute({
                        key: "size",
                        value: option,
                      })
                  }
                  }
                  dataTestId="chat-footer-size"
                  regenerateImage={regenerateImage}
                />
              </div>
            </div>
            {!regenerateImage && (
              <div className={classNames(styles.imageCredits, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}>
                <FormattedMessage
                  id={
                    Number(imageAttributes?.size?.credits_per_message) === 1
                      ? "chat.image.one.credit"
                      : "chat.image.credit.perQuery"
                  }
                  values={{
                    credits:
                      Number(imageAttributes?.count) *
                      Number(imageAttributes?.size?.credits_per_message),
                  }}
                />
              </div>
            )}
          </div>
        )}
    </>
  );
};
