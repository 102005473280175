import {
    ReactNode,
    Dispatch,
    SetStateAction,
} from "react";
import styles from "./MainView.module.scss";
import classNames from "classnames";

import { Navbar } from "../Navbar";

import { ChatType, IChat } from "redux/actions";
import { IUploadFile } from "pages/ChatPage";

import { useSelector } from "redux/hooks";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";
import { loadMoreMessages } from "utils/chat";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps {
    children: ReactNode;
    contentContainerRef?: React.RefObject<HTMLDivElement>;
    docuemntModel?: boolean;
    setChatSetting?: Dispatch<SetStateAction<boolean>>;
    isFileUploading?: boolean;
    uploadingFiles?: IUploadFile[];
    onSelectChatItem?: (chatItem: IChat | undefined) => void;
    onStartNewChat?: ({ toChat, PrivateChat }: { toChat: boolean, PrivateChat?: boolean }) => void;
    isMainScreenOpen?: boolean;
    handleScroll: () => void;
}

export const MainLeftContainer = ({
    children,
    contentContainerRef,
    isMainScreenOpen,
    docuemntModel,
    setChatSetting,
    handleScroll,
    uploadingFiles,
    onSelectChatItem,
    onStartNewChat,
    isFileUploading,
}: IProps) => {

    const { newMessages, messages, messagesPagination, isLoadMoreMessages } = useSelector((state) => state.chatReducer);
    const { gptModel, theme } = useSelector((state) => state.authReducer);
    const { includeRoute, pathname } = useRouter();

    const { isOpen } = useToggleSidebar();
    const chatId = pathname.split("/")[3];
    const isAccount = includeRoute(RoutePaths.Account);
    const isChat = includeRoute(RoutePaths.Chat);
    const isIChatFooter = pathname.includes("chat/new");
    const isShareChat = window.location.pathname.includes("share-chat");
    const isSettings = includeRoute(RoutePaths.Settings);

    const { sidebarWidth } = useSidebar();
    const { width } = useWindowSize();

    const handleMessageScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (messagesPagination.page < messagesPagination.lastPage) {
            const { scrollTop } = event?.currentTarget;
            const scrollPositionFromTop = scrollTop;
            const loadThreshold = 1;

            if (scrollPositionFromTop <= loadThreshold && !isLoadMoreMessages) {
                loadMoreMessages(chatId, messagesPagination, contentContainerRef);
            }
        }
    };

    return (
        <>
            <Navbar
                onSelectChatItem={onSelectChatItem}
                onStartNewChat={onStartNewChat}
                isMainScreenOpen={isMainScreenOpen}
                docuemntModel={docuemntModel}
                setChatSetting={setChatSetting}
                isFileUploading={isFileUploading}
                uploadingFiles={uploadingFiles}
            />

            <div
                ref={contentContainerRef}
                className={classNames(styles.content, {
                    [styles.light]: theme === "light" && isChat,
                    [styles.dark]: theme === "dark" && isChat,
                    [styles.isSidebarOpen]: isOpen && !isShareChat,
                    [styles.chatContent]: isChat,
                    [styles.isShareChat]: isShareChat,
                    [styles.IGChatContent]:
                        gptModel?.type?.includes(ChatType.image) &&
                        !gptModel?.type?.includes(ChatType.image_chat) &&
                        isChat,
                        [styles.IGChatContentsidebarOpen]:
                        gptModel?.type?.includes(ChatType.image) &&
                        !gptModel?.type?.includes(ChatType.image_chat) &&
                        isChat && isOpen,
                    [styles.settingsContent]: isSettings,
                    [styles.settingsContentlight]:
                        isSettings && isAccount && theme === "light",
                    [styles.settingsContentdark]:
                        isSettings && isAccount && theme === "dark",
                    [styles.shareChat]: isShareChat && theme === "light",
                    [styles.isSideBarClose]: !isOpen,
                    [styles.IChatContent]:
                        (newMessages[0]?.images && newMessages[0]?.images.length > 0) ||
                        (messages[0]?.images &&
                            messages[0]?.images.length > 0 &&
                            !isIChatFooter),
                    [styles.IUplodingFile]:
                        uploadingFiles &&
                        uploadingFiles.length > 0 &&
                        (newMessages.length > 0 || !isIChatFooter),
                        [styles.resizeNavbarContent]: width > 768 && isOpen && includeRoute(RoutePaths.Team) && !isShareChat,
                        [styles.resizeNavbar]: sidebarWidth >= 400 && isOpen && !isShareChat && includeRoute(RoutePaths.Team)
                })}
                onScroll={(e) => {
                    if (isChat) handleMessageScroll(e);
                    handleScroll();
                }}
            >
                {children}
            </div>
        </>
    );
};
