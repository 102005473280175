
import { FormattedMessage, useIntl } from "react-intl";

import styled from "styled-components";
import classNames from "classnames";
import styles from "./PriceCard.module.scss";
import {
    // CheckedIcon,
    PriceCardCheckHover,
    PriceCardCheck
} from "../CheckedIcon";
import { RoutePaths } from "pages/routePaths";

import { IterFont } from "../IterFont";
import { planTable } from "pages/SettingsPage/pages/CurrentPlan/components/PricingTable/PricingTable";
import CutsomButton from "components/Button";

import useRouter from "hooks/useRouter";
import { IPlan, IUserDetail } from "redux/actions";
import { EThemeType } from "redux/reducers";

interface IProps {
    isLoggedIn?: boolean;
    planItem: IPlan;
    userDetail?: IUserDetail;
    theme?: EThemeType;
    requiredPlan?: IPlan;
    upgradePlanLoading?: boolean;
    onUpgradePlan?: (selectedPlan: IPlan) => void;
}

interface IplanTableCol {
    label: React.ReactNode;
    value?: string | React.ReactNode;
    icon?: boolean;
    isShowLangingPage?: boolean;

}

const Button = styled(CutsomButton)`
  font-size: 14px;
  border-radius: 5px;
  background: #5B71F5;
  width: 100%;
`;

const PlanContainer = styled.div`
display: flex;
justify-content: space-between;
align-item:center;
width: 100%;
padding: 0px 0px 15px 0px;
 &:hover .setFrequency {
    color: white !important;
  }
@media screen and (min-width: 1492px){
padding: 0px 0px 21px 0px;
}
`;

const PlanTable = styled.div`
padding-bottom : 30px;
@media screen and (min-width: 1492px){
padding-bottom : 50px;
}
`;

const PlanTableCol = styled.div`
display : flex;
align-item: center;
justify-content: space-between;
margin-bottom: 15px;
gap: 10px;
.hover-icon {
    display: none; /* Hidden by default */
  }

  .default-icon {
    display: inline-block; /* Shown by default */
  }

@media screen and (min-width:1492px){
// gap: 20px;
margin-bottom: 26px;
}
`;

const Separator = styled.div`
 border-top: 1px solid #454646;
  margin-top: 4px;
  margin-bottom: 4px;
  padding-bottom: 15px;
@media screen and (min-width: 1492px){
padding-bottom: 35px;
}
`;

const MainContainer = styled.div<{ themeMode?: EThemeType }>`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: ${({ themeMode }) => (themeMode === "dark" ? "#23212C" : "#fff")};
  padding: 20px 10px;
 &:hover {
 cursor:pointer;
    background: #5B71F5; 

      .default-icon {
      display: none;
    }

    .hover-icon {
      display: inline-block;
    }
      
    .${styles.setFrequency}, .${styles.PriceCardLablwhite}, .${styles.PriceCardtext} {
      color: white !important;
      opacity:1;
    }
      ${Separator}
      {
      border-top: 1px solid #fff;
      }
        ${Button} {
      background: white;
      color: #5B71F5;  
    }
  }
   
@media screen and (min-width: 1158px){
padding: 22px;
}
@media screen and (min-width: 1492px){
padding: 35px;
}
`;

const CreditPerday = styled.span`
margin-left: 4px;
font-size: 12px;
font-weight: 400;
@media screen and (min-width: 1340px){
font-size: 14px;
}`;

const PopularPlan = styled.span`
display: flex;
padding: var(--Space-0, 0px) 7px;
justify-content: center;
align-items: center;
color: #FFF;
font-family: Raleway;
font-size: 10px;
font-style: normal;
font-weight: 600;
border-radius: 5px;
background: #2A2831;
line-height: 26px;
`;

export const PriceCardDesktop = ({
    isLoggedIn,
    planItem,
    userDetail,
    theme,
}: IProps) => {
    const { formatMessage } = useIntl();
    const { push } = useRouter();
    let planTableCols: IplanTableCol[] = [];
    const planName = planItem.name.toLocaleLowerCase();
    const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

    planTable.forEach((planTableRow) => {
        let obj: IplanTableCol = {
            label: planTableRow.planName,
            value: "",
            icon: false,
            isShowLangingPage: planTableRow.isShowLangingPage,

        };
        for (let key in planTableRow) {
            if (key.includes(planName)) {
                if (key === planName)
                    obj.icon = planTableRow[key];
                else obj.value = planTableRow[key];
            }
        }
        planTableCols.push(obj);
    });

    return (
        <>
            <MainContainer themeMode={theme}
            >
                <PlanContainer
                >
                    <div className="flex flex-col flex-1">
                        <span className={`${planItem.name === 'Professional' ? 'flex justify-between' : ''} ${theme === "dark" ? styles.setFrequencydark : styles.setFrequency}`}
                        >
                            {planItem.name === "Free" ? (
                                <FormattedMessage id="landing.price.table.freeforever" />
                            ) : (
                                <FormattedMessage id={`plan.type.${planItem.name}`} />
                            )}
                            {planItem.name === 'Professional' &&
                                <PopularPlan><FormattedMessage id="plan.Popular" /></PopularPlan>
                            }
                        </span>
                        <span
                            className={classNames("text-4xl font-bold", planItem.name === 'Professional' ? 'my-1 mb-[15px]' : 'my-4',
                                theme === "dark" ? styles.setFrequencydark : styles.setFrequency
                            )}
                        >
                            <IterFont>
                                {planItem.name === "Free" ? (
                                    <FormattedMessage id="plan.type.Free" />
                                ) : (
                                    `$${planItem.discounted_price}/m`
                                )}
                            </IterFont>
                        </span>
                        <div className="w-full flex items-center justify-between ">
                            <span
                                className={classNames(
                                    theme === "dark" ? styles.setFrequencydark : styles.setFrequency
                                )}
                            >
                                <FormattedMessage id="landing.price.credits" />
                            </span>
                            <span
                                className={classNames("",
                                    theme === "dark" ? styles.setFrequencydark : styles.setFrequency
                                )}
                            >
                                <IterFont>
                                    <CreditPerday>
                                        {planItem.name === "Free" ? (
                                            <>
                                                {planItem.credits}{" "}
                                                {planItem.name === "Free" && formatMessage({ id: "landing.price.free.credits" })}
                                            </>
                                        ) : (
                                            planItem.credits
                                        )}
                                    </CreditPerday>
                                </IterFont>
                            </span>
                        </div>
                    </div>
                </PlanContainer>
                <Separator />
                <PlanTable>
                    {planTableCols.map((item, index) => {
                        if (item?.isShowLangingPage) {
                            return (
                                <PlanTableCol
                                    key={`rows-${index}`}
                                >
                                    <span className={`${theme === 'dark' ? styles.PriceCardLabl : styles.PriceCardLablwhite} flex items-center` }>{item.label}</span>
                                    <span
                                        className={classNames(styles.PriceCardtext, {
                                            [styles.dark]: theme === "dark",
                                            [styles.light]: theme === "light",
                                        })}
                                    >
                                        {item.icon ? (
                                            <span className="default-icon">
                                                <PriceCardCheck />
                                            </span>
                                        ) : (
                                            item.value
                                        )}
                                        {item.icon && (
                                            <span className="hover-icon">
                                                <PriceCardCheckHover />
                                            </span>
                                        )}
                                    </span>
                                </PlanTableCol>
                            );
                        }
                        // );
                    })}
                </PlanTable>

                {!isLoggedIn && (
                    <div className="flex flex-1 items-start justify-end">
                        {
                        // userDetail?.user?.activeSubscription.name === planItem.name &&
                        //     userDetail?.user?.activeSubscription.duration ===
                        //     planItem.duration ? (
                        //     <Button data-testid={planItem.name} >
                        //         <span className={theme === "dark" ? styles.setSelecteddark : styles.setSelectedWhite}
                        //         >
                        //             <FormattedMessage id="setting.plan.selected" />
                        //         </span>
                        //     </Button>
                        // ) :
                         planItem.name === "Free" ? (
                            <Button
                                type="button"
                                variant="primary"
                                onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                                data-testid={planItem.name}
                            >
                                <FormattedMessage id="landing.price.tryForFree" />
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                variant="primary"
                                onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                                data-testid={planItem.name}
                            >
                                <FormattedMessage id="landing.price.getStarted" />
                            </Button>
                        )}
                    </div>
                )}
            </MainContainer>
        </>
    );
};
