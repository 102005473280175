import { EThemeType } from "redux/reducers";
import styled from "styled-components";

interface ILineSeperatorProps {
  color?: string;
  variant?: EThemeType;
}

export const LineSeparator = styled.div<ILineSeperatorProps>`
  width: 100%;
  border-top: 1px solid
    ${({ variant, theme }) =>
      variant === "light" ? theme.borderLight : theme.borderDark};
`;

interface ISeperatorProps {
  width?: string;
  height?: string;
}

export const Separator = styled.div<ISeperatorProps>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "20px")};
  
`;
