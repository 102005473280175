import { ButtonHTMLAttributes, ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "theme";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  full?: boolean;
  white?: boolean;
  children: ReactNode;
  small?: boolean;
  isloading?: boolean;
  variant?: "primary" | "outlined-primary" | "outlined" | "latest-primary" | "outline-primary" | "cancel" | "delete";
  teamNav?: boolean;
  documentCancel?: boolean;
  teamMdNav?: boolean;
  hero?: boolean;
  navBtn?:boolean;
  planBtn?:boolean;
}

const getBorderColor = (props: IProps) => {
  const { variant } = props;
  if (variant === "primary") {
    return "transparent";
  } else if (variant === "outlined") {
    return "#232527";
  } else if (variant === "outlined-primary") {
    return theme.primaryColor;
  } else if (variant === "outline-primary") {
    return "#7C4DFF";
  } else if(variant ==='cancel'){
    return "#EBE5FF";
  } else {
    return "transarent";
  }
};

const getBackgroundColor = (props: IProps) => {
  const { variant, documentCancel } = props;
  if (variant === "primary") {
    return "linear-gradient(92.31deg, #8c49f7 0%, #6c53ff 100%)"; // old color
  } else if (variant === "latest-primary") {
    return "linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%)"; // new design color
  } else if(documentCancel===true){
    return " linear-gradient(92.54deg, #4693E8 -29.87%, #6C53FF 100%)";
  }else {
    return "none";
  }
};

const Button = styled.button<IProps>`
  border: 1px solid ${(props) => getBorderColor(props)};
  background:${({ teamNav,documentCancel, variant}) => (teamNav ? "linear-gradient(92deg, #8C49F7 0%, #6C53FF 100%) !important" : documentCancel? " linear-gradient(92.54deg, #4693E8 -29.87%, #6C53FF 100%)" : variant==='cancel'? '#EBE5FF': variant==='delete'? "#DF3A32": " transparent")} ;
  background-color: ;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  text-align: center;
  background-image: ${(props) => getBackgroundColor(props)};

  color: ${({planBtn})=>(planBtn?"#5b71f5":"#ffffff")};
  border-radius: ${({planBtn}) => (planBtn ? '5px': "10px")};
  padding: ${({ small,documentCancel, teamNav, hero ,navBtn, planBtn}) => (small ? " 5px 10px" : documentCancel?"10px 20px":teamNav? "9px 15px": hero? "10px 20px" : planBtn? "10px 20px": navBtn?"10px 20px" : "9px 12px" )};
  text-decoration: none;
  width: ${({ full, planBtn }) => (full ? "100%" :planBtn? "100px" : "auto")};
  position:${({teamMdNav})=> teamMdNav? 'absolute': 'relative'} ;
  right: ${({teamMdNav})=> teamMdNav? '45px': 'auto' };
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  @media (max-width: 576px) {
    right: ${({ teamMdNav }) => (teamMdNav ? '22px' : 'auto')};
  }

  @media (max-width: 344px) {
    padding: ${({ teamMdNav }) => (teamMdNav && '9px 12px')};
  }
    @media (min-width: 1440px){
     font-size: ${({ hero}) =>(hero ? "22px" : "16px")};
    padding: ${({ hero }) => (hero ? '18px 24px': "10px 20px")};
    }

  &:hover {
    background-image: ${(props) => getBackgroundColor(props)};
    color: ${({ planBtn }) => (planBtn ? "" : "#ffffff")};
    opacity: 1;
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    
  }
`;

export const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const SymbolSpinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 18px;
  position: absolute;
  left: 50%;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: ${({ theme }) => theme.whiteColor};
  margin: -1px 0 -3px -9px;
`;

const Invisible = styled.div`
  color: rgba(255, 255, 255, 0);
`;

export const CutsomButton = (props: IProps) => {
  return (
    <Button {...props}>
      {props.isloading ? (
        <>
          <SymbolSpinner icon={faCircleNotch} />
          <Invisible>{props.children}</Invisible>
        </>
      ) : (
        props.children
      )}
    </Button>
  );
};
