import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import styles from "./Features.module.scss";

import {
  RealTimeIcon,
  UserFriendlyIcon,
  MultiLanguageIcon,
  TeamManagmentIcon,
} from "../components/Icons";

import { FeatureSection } from "../components/FeatureSection";

import { useSelector } from "redux/hooks";

export const Features = () => {
  const theme = useSelector((state) => state.authReducer.theme);
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.featureContainer, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
        style={{
          backgroundImage: `url(/landing/${theme}/BestFeatures-bg.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className={styles.textContainer}>
          <span
            className={classNames(styles.title, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="landing.feature.title" />
          </span>
          <p
            className={classNames(styles.desc, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="landing.feature.desc" />
          </p>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <RealTimeIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.realTime" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.realTime.desc" />
            </p>
          </div>
          <div className={styles.col}>
            <UserFriendlyIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.userFriendly" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.userFriendly.desc" />
            </p>
          </div>
          <div className={styles.col}>
            <MultiLanguageIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.multiLanguage" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.multiLanguage.desc" />
            </p>
          </div>
          <div className={styles.col}>
            <TeamManagmentIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.teamManagment" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.teamManagment.desc" />
            </p>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.featureTypeContainer, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.ask.deftgpt" })}
          normalText={formatMessage({ id: "landing.feature.ask" })}
          description={formatMessage({ id: "landing.feature.ask.desc" })}
          featureType="askdeftgpt"
          backgroundUrl={`/landing/${theme}/AskGPT-bg.png`}
        />

        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.generate" })}
          normalText={formatMessage({ id: "landing.feature.generateAiArt" })}
          description={formatMessage({
            id: "landing.feature.generateAiArt.desc",
          })}
          featureType="generateai"
          $reverse
          backgroundUrl={`/landing/${theme}/GenerateAI-bg.png`}
        />

        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.uploadImage" })}
          normalText={formatMessage({ id: "landing.feature.uploadImageTitle" })}
          description={formatMessage({ id: "landing.feature.uploadImage.desc" })}
          featureType="uploadimage"
          $uploadImage={true}
          backgroundUrl={`/landing/${theme}/UploadImage-bg.png`}
        />

        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.upload" }) + '\n'}
          normalText={formatMessage({ id: "landing.feature.uploadTitle" })}
          description={formatMessage({ id: "landing.feature.upload.desc" })}
          featureType="uploaddocument"
          $reverse
          $document={true}
          backgroundUrl={`/landing/${theme}/UploadDocuments-bg.png`}
        />
        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.private" })}
          description={formatMessage({ id: "landing.feature.private.desc" })}
          featureType="privatechat"
          backgroundUrl={`/landing/${theme}/PrivateChat-bg.png`}
          $PrivateChat={true}

        />
        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.bot" })}
          description={formatMessage({ id: "landing.feature.bot.desc" })}
          // imgSrc={
          //   theme === "dark"
          //     ? "/landing/feature-6-dark.svg"
          //     : "/landing/feature-6-light.svg"
          // }
          featureType="botfeature"
          // alt="Upload documents and chat regarding them"
          $reverse
          $botFeature={true}
          backgroundUrl={`/landing/${theme}/BOTFeatures-bg.png`}
        />
        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.language" })}
          description={formatMessage({ id: "landing.feature.language.desc" })}
          featureType="language"
          backgroundUrl={`/landing/${theme}/SelectLanguage-bg.png`}
          $language={true}
        />
      </div>
    </div>
  );
};
