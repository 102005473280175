import styled from "styled-components";
import { PriceCard, PriceCardDesktop } from "../components/PriceCard";
import { EPlanDuration, IPlan, IUserDetail } from "redux/actions";
import { EThemeType } from "redux/reducers";
interface IProps {
  isLoggedIn?: boolean;
  isSwitch?: boolean;
  plans: IPlan[];
  userDetail?: IUserDetail;
  theme?: EThemeType;
  duration?: EPlanDuration;
  requiredPlan?: IPlan;
  upgradePlanLoading?: boolean;
  onUpgradePlan?: (selectedPlan: IPlan) => void;
  onChange?: (type?: EPlanDuration) => void;
}
export const MobilePricing = ({
  isLoggedIn,
  // isSwitch,
  plans,
  userDetail,
  theme,
  duration,
  requiredPlan,
  upgradePlanLoading,
  onUpgradePlan,
  // onChange,
}: IProps) => {
  const Container = styled.div`
    display: none;
    @media screen and (min-width: 1440px) {
      display: flex;
      gap: 16px;
      width: 100%;
      justify-content: center;
      padding:100px 0px;
    }
    @media screen and (min-width: 1024px) {
      display: flex;
      gap: 9px;
      width: 100%;
      justify-content: center;
      padding:80px 0px;
    }
  `;
  const isLogin = userDetail?.token ? true : false;
  
  return (
    <>
      {!isLogin && (
        <Container>
          {plans
            .filter((item) => {
              if (item.name === "Free") {
                return item;
              }
              if (item.duration === "month" && duration === "month") {
                return item;
              }
              if (item.duration === "year" && duration === "year") {
                return item;
              }
            })
            .map((planItem, index) => (
              <PriceCardDesktop
                key={`price-${duration}-${index}`}
                isLoggedIn={isLoggedIn}
                planItem={planItem}
                userDetail={userDetail}
                theme={theme}
                requiredPlan={requiredPlan}
                upgradePlanLoading={upgradePlanLoading}
                onUpgradePlan={onUpgradePlan}
              />
            ))}
        </Container>
      )}
      <div className="block lg:hidden">
        {/* {isSwitch && <PriceSwitch duration={duration} onChange={onChange} />} */}
        {plans
          .filter((item) => {
            if (item.name === "Free") {
              return item;
            }
            if (item.duration === "month" && duration === "month") {
              return item;
            }
            if (item.duration === "year" && duration === "year") {
              return item;
            }
          })
          .map((planItem, index) => (
            <PriceCard
              key={`price-${duration}-${index}`}
              isLoggedIn={isLoggedIn}
              planItem={planItem}
              userDetail={userDetail}
              theme={theme}
              requiredPlan={requiredPlan}
              upgradePlanLoading={upgradePlanLoading}
              onUpgradePlan={onUpgradePlan}
            />
          ))}
      </div>
    </>
  );
};

