import { useWindowSize } from "hooks/useWindowSize";
export const FooterLogo = () => {
  const { width } = useWindowSize();
  return (
    <img
      src="/logo-Footer.svg"
      alt="logo"
      loading="lazy"
      width={width <=1440 ? "42px":"62px"}
      height={width<=1440 ?"42px" :"62px"}
    />
  );
};