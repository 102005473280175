import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./Innovate.module.scss";
import { useSelector } from "redux/hooks";
import { ModelIcon1, ModelIcon2, ModelIcon3, ModelIcon4, ModelIcon5, ModelIcon6, ModelIcon7, ModelIcon8, ModelIcon9, ModelIcon10 } from "../components/Icons";
import { useEffect, useRef } from "react";

export const Innovate = () => {
  const theme = useSelector((state) => state.authReducer.theme);
  const icons = [
    { component: <ModelIcon1 />, classSuffix: "icon1" },
    { component: <ModelIcon2 />, classSuffix: "icon2" },
    { component: <ModelIcon3 />, classSuffix: "icon3" },
    { component: <ModelIcon4 />, classSuffix: "icon4" },
    { component: <ModelIcon5 />, classSuffix: "icon5" },
    { component: <ModelIcon6 />, classSuffix: "icon6" },
    { component: <ModelIcon7 />, classSuffix: "icon7" },
    { component: <ModelIcon8 />, classSuffix: "icon8" },
    { component: <ModelIcon9 />, classSuffix: "icon9" },
    { component: <ModelIcon10 />, classSuffix: "icon10" },
  ];
  const duplicatedIcons = [...icons, ...icons];

  const iconWrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const wrapper = iconWrapperRef.current;

    if (wrapper) {
      const updateAnimation = () => {
        const totalWidth = wrapper.scrollWidth / 2; // To account for the duplicated icons
        wrapper.style.setProperty("--scroll-width", `${totalWidth}px`);
        const animationDuration = totalWidth / 130; // Adjust speed as needed
        wrapper.style.setProperty("--animation-duration", `${animationDuration}s`);
      };

      updateAnimation(); // Initial setup
      window.addEventListener('resize', updateAnimation); // Update width on resize

      return () => window.removeEventListener('resize', updateAnimation); // Cleanup on unmount
    }
  }, [duplicatedIcons]);

  return (
    <section
      className={classNames(styles.innovateSection, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      style={{
        backgroundImage: `url(/landing/${theme}/Innovate-bg.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={styles.left}>
        <h1
          className={classNames(styles.h1, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <span className={styles.gradient}>
            <FormattedMessage id="landing.innovate.heading" />
          </span>
          <br />
          <p className={styles.subtitle}>
            <FormattedMessage id="landing.innovate.title" />
          </p>
        </h1>
        <p
          className={classNames(styles.text, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <FormattedMessage id="landing.innovate.desc" />
        </p>

      </div>

      <div className={styles.iconContainer}>
        <div
          ref={iconWrapperRef}
          className={styles.iconWrapper}>
          {duplicatedIcons.map((icon, index) => (
            <div
              key={index}
              className={`${styles.modelIcon} ${styles[icon.classSuffix]}`}
            >
              {icon.component}
            </div>
          ))}
          {duplicatedIcons.map((icon, index) => (
            <div
              key={`duplicate-${index}`}
              className={`${styles.modelIcon} ${styles[icon.classSuffix]}`}
            >
              {icon.component}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}