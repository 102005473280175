import { Link } from "react-router-dom";
import { PrimaryLogo } from "components/Logo";

interface IProps {
  lang?: string;
  onClick?: () => void;
}

export const Logo = ({ lang, onClick }: IProps) => {
  return (
    <>
      <div
        className="md:mr-10 flex items-center lg:hidden"
        data-testid="header-xs-logo"
        onClick={onClick}
      >
        <PrimaryLogo />
      </div>
      <Link
        to={`/${lang}`}
        className="mr-10 items-center hidden lg:flex"
        data-testid="header-logo"
      >
        <PrimaryLogo />
      </Link>
    </>
  );
};
