import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./regeneratewithModel.module.scss";
// import { ModalItem } from "./ModalItem";

import { ChatType, IChat, IChatModel, IMessage } from "redux/actions";
import { useSelector } from "redux/hooks";
import { useLocation } from "react-router-dom";
import { DownIcon, RegenerateIcon } from "../icons";
import { Dropdown, CustomFlowbiteTheme } from "flowbite-react";
import { Tooltip } from "components";
// import { ImageGenerationOptions } from "pages/ChatPage/components/chatFooter/components/ImageGenerationOption";
// import CustomButton from "components/Button";
// import styled from "styled-components";

interface IProps {
    chatItem?: IChat;
    onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
    messageIndex?: number;
    message?: IMessage;
}

// const Button = styled(CustomButton)`
// color: #FFF;
// font-family: Raleway;
// font-size: 10px;
// font-style: normal;
// font-weight: 600;
// line-height: 24px; /* 240% */
// padding: 5px 25px;
// //   font-size: 14px;
// //   padding: 4px 12px;
// `;

export const RegenerateWithModel = ({
    onRegenerate,
    messageIndex,
    message,
}: IProps) => {
    const location = useLocation();
    const { gptModel, theme } = useSelector((state) => state.authReducer);
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);
    const { newMessages, messages } = useSelector((state) => state.chatReducer);

    const [tab, setTab] = useState<"text" | "image">("text");

    const [filteredModels, setFilteredModel] = useState<IChatModel[]>([]);
    const [regenerateModel, setRegenerateModel] = useState<IChatModel>();
    const [hoveredModel, setHoveredModel] = useState<boolean>(false);
    const [openDropDown, setOpenDropDown] = useState<boolean>(false);
    const [modelName, setModelName] = useState<string>('');

    const dropdownRef = useRef<HTMLDivElement>(null);

    // Toggle open state on dropdown trigger click
    const handleDropdownClick = () => {
        setOpenDropDown((prev) => !prev);
    };

    //   Detect click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event?.target as Node)) {
                setOpenDropDown(false);
                setHoveredModel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (gptModel?.type?.includes(ChatType.image)) setTab("image");
        else setTab("text");
    }, [gptModel]);

    const handleFilter = () => {
        let filteredModels = chatModels;
        const shouldFilterByDocument =
            (messages[0]?.files ||
                messages[0]?.images ||
                location.pathname === "/chat/new") &&
            tab === "text";

        if (shouldFilterByDocument) {
            if (
                (newMessages?.length ?? 0) > 0 &&
                !newMessages[0]?.files &&
                (!newMessages[0]?.images || (newMessages[0]?.images?.length ?? 0) === 0)
            )
                filteredModels = chatModels;
            else if (
                newMessages[0]?.images?.length > 0 ||
                messages[0]?.images?.length > 0
            )
                filteredModels = chatModels.filter(
                    (model) =>
                        model.type.includes(ChatType.image_chat) &&
                        model.type.includes("document")
                );
            else
                filteredModels = chatModels.filter((model) =>
                    model.type.includes("document")
                );
        }
        setFilteredModel(filteredModels);
    };
    useEffect(() => {
        handleFilter();
    }, [tab, chatModel])

    useEffect(() => {
        let answerModel = chatModels.filter(
            (model) => model.id === message?.model_id
        );
        let regenerateModel = chatModels.filter(
            (model) => model.id === message?.regenerate_model_id
        );
        setRegenerateModel(answerModel[0] ?? regenerateModel[0]);
        setModelName(answerModel[0]?.name ?? regenerateModel[0]?.name);
    }, [message !== null, openDropDown]);

    const customTheme: CustomFlowbiteTheme["dropdown"] = {
        content:
            "modelModalScrollBar ml-1 mr-1 mt-2.5 rounded-[10px]",
        floating: {
            base: "z-10 w-[190px] rounded-lg shadow-[0px_33px_9px_0px_rgba(237,237,237,0.01),0px_21px_8px_0px_rgba(237,237,237,0.11),0px_12px_7px_0px_rgba(237,237,237,0.38),0px_5px_5px_0px_rgba(237,237,237,0.65),0px_5px_9px_0px_rgba(237,237,237,0.75)]  ml-[32px] outline-none border-none dark:shadow-none focus:outline-none dark:bg-[#252526]",
            style: {
                dark: "bg-[#252526] text-dark-light dark:bg-[#252526]",
                light: "bg-white text-light-default",
                auto: "bg-white text-light-default dark:bg-[#252526] dark:text-dark-default",
            },
        },
    };

    let hideTimeout: NodeJS.Timeout | null = null;

    const handleMouseEnter = () => {
        if (hideTimeout) {
            clearTimeout(hideTimeout);
            hideTimeout = null;
        }
        setHoveredModel(true);
    };

    const handleMouseLeave = () => {
        hideTimeout = setTimeout(() => setHoveredModel(false), 100);
    };

    // const [selectedModel, setSelectedModal] = useState<IChatModel>({} as IChatModel);

    // const 

    return (
        <>
            <div ref={dropdownRef} className="cursor-pointer"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div className={classNames(styles.regenrationContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}>
                    <Tooltip
                        regenrate={true}
                        regenrateTooltip={true}
                        control={
                            <div
                                className={classNames(styles.icon, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                    [styles.iconContainer]: true,
                                })}
                                onClick={() => {
                                    setHoveredModel(false);
                                    setTimeout(() => setOpenDropDown(false), 10);
                                    onRegenerate &&
                                        onRegenerate(
                                            messageIndex ? messageIndex : 0,
                                            {} as IChatModel
                                        )
                                }
                                }
                                data-testid="regenerate-answer-btn"
                            >
                                <RegenerateIcon theme={theme} />
                            </div>
                        }
                        placement="top"
                        theme="light"
                    >
                        <div>
                            <FormattedMessage id="answer.tooltip.regenerate" />
                        </div>
                    </Tooltip>
                    <span onClick={handleDropdownClick}>
                        <Tooltip
                            regenrate={true}
                            tooltipAdjustement={true}
                            control={
                                <Dropdown
                                    theme={customTheme}
                                    label=""
                                    dismissOnClick={tab === 'image' ? false : true}
                                    style={{ width: '175px' }}
                                    renderTrigger={() => (
                                        <div
                                            className={classNames(styles.icon, {
                                                [styles.light]: theme === "light",
                                                [styles.dark]: theme === "dark",
                                            })}
                                            style={{ paddingTop: '6px', paddingBottom: '6px' }}
                                        >
                                            <img
                                                data-testid="regeneration-model-filter"
                                                className={(
                                                    regenerateModel?.image_url ??
                                                    chatModel?.image_url ??
                                                    ""
                                                ).includes("flux.png") ? `w-[13px] max-h-[13px] ${styles.imagebackground}` : "w-[13px] max-h-[13px] "}
                                                src={
                                                    regenerateModel?.image_url ??
                                                    chatModel?.image_url ??
                                                    "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"
                                                }
                                            />
                                            {(hoveredModel || openDropDown) && (
                                                <div
                                                    className={classNames(styles.modalName, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}
                                                >
                                                    {modelName ?? chatModel?.name ?? 'GPT-4o mini'}
                                                </div>
                                            )}
                                            <DownIcon theme={theme} />
                                        </div>
                                    )}
                                >
                                    <div onMouseOver={(event: any) => {
                                        event.stopPropagation();

                                    }}>
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <div
                                                className={classNames(styles.DropDownbody, {
                                                    [styles.light]: theme === "light",
                                                    [styles.dark]: theme === "dark",
                                                })} >
                                                {chatModels.length > 0 ? (
                                                    filteredModels
                                                        .filter((model) => model?.type?.includes(tab))
                                                        .map((model, index) => (

                                                            <Dropdown.Item key={index} className={`${classNames(styles.container, {
                                                                [styles.light]: theme === "light",
                                                                [styles.dark]: theme === "dark",
                                                            })} hover:rounded-md hover:cursor-pointer py-1 transition-none`}
                                                                onClick={() => {
                                                                    setOpenDropDown(false);
                                                                    setHoveredModel(false);
                                                                    onRegenerate!(messageIndex ? messageIndex : 0, model);

                                                                }}
                                                                // style={{marginTop : index===0 ? '4px': ''}}
                                                                data-testid='select-modal'>
                                                                <div className={`${styles.imagedropdownContainer} ${model.name === "flux-schnell" ? styles.image : ""}`}>
                                                                    <img src={model.image_url} />
                                                                </div>
                                                                <div className={styles.textContainer}>
                                                                    <div className={classNames(styles.dropdownModalName, {
                                                                        [styles.dark]: theme === "dark",
                                                                    })}>
                                                                        {model.name}
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                        ))
                                                ) : (
                                                    <div className={styles.message}>
                                                        <FormattedMessage id="gptmodel.no.available" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* )} */}
                                    </div>
                                </Dropdown>
                            }
                            placement="top"
                            theme="light"
                        >
                            <div>
                                <FormattedMessage id="answer.tooltip.regenerate.other.model" />
                            </div>
                        </Tooltip>
                    </span>
                </div>
            </div>

        </>
    );
};
