import { useEffect, useRef } from "react";

import { Main } from "components/Main";
import { Hero, Features, Pricing, ChromeExtension } from "./sections";
import { RoutePaths } from "../../pages/routePaths";

import useRouter from "../../hooks/useRouter";
import { useSelector } from "redux/hooks";
import { Innovate } from "./sections/Innovate";

export const LandingPage = () => {
  const { pathname, location } = useRouter();
  const { userDetail } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  const featureRef = useRef(null);
  const priceRef = useRef(null);
  const heroRef = useRef(null);
  const innovateRef = useRef(null);

  useEffect(() => {
    if (pathname === `/${lang}${RoutePaths.Root}` && location.hash)
      scrollToAnchor(location.hash);
  }, [location.hash]);

  const scrollToAnchor = (anchorName: string) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName.split("#")[1]);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    }
  };

  return (
    <Main
      // isHasBgOnFooter
      sectionRefs={{
        feature: featureRef,
        price: priceRef,
        hero: heroRef,
        innovate:innovateRef,
      }}
    >
      <section id="hero" ref={heroRef}>
        <Hero />
      </section>
      <section id="feature" ref={featureRef}>
        <Features />
      </section>
      <section id="innovate" ref={innovateRef}>
        <Innovate />
      </section>
      <section id="pricing" ref={priceRef}>
        <Pricing />
      </section>
      <ChromeExtension />
    </Main>
  );
};
