import { memo } from "react";
import classNames from "classnames";
import styles from "../DDMenu.module.scss";

import { DownArrow } from "../Icons";

import {  Dropdown } from "flowbite-react"; // Import Flowbite Dropdown component

import { useSelector } from "redux/hooks";

import { ISize } from "redux/actions";

interface IProps {
  selectedOption?: ISize;
  options?: ISize[];
  onSelect: (option: ISize) => void;
  dataTestId?: string;
  regenerateImage?: boolean;
}

export const SizeDD = memo(({ selectedOption, options, onSelect, dataTestId, regenerateImage }: IProps) => {
  const themeMode = useSelector((state) => state.authReducer.theme);

  return (
    <Dropdown
      label=''
      className={classNames(styles.dropdownCustomBorder,{
        [styles.light]: themeMode === 'light',
        [styles.dark]: themeMode === 'dark',
      })} 
      renderTrigger={() => (
        <div
          className={classNames(styles.menu, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark" ,
            [styles.regenerateImage]: regenerateImage
          })}
        >
          {selectedOption?.value} <DownArrow />
        </div>
      )}
   
    >
      {options?.map((option) => (
        <Dropdown.Item
          className={classNames("transition-none",styles.menuItem, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
            [styles.isSelected]: option.value === selectedOption?.value,
          })}
          onClick={() => onSelect(option)}
          key={`${option.value}`}
          data-testid={dataTestId}
        >
          <div className={styles.text}>{option.value}</div>
        </Dropdown.Item>
      ))}
    </Dropdown >
  );
}
);
