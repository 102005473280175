import { memo } from "react";
import classNames from "classnames";
import styles from "../DDMenu.module.scss";

import { DownArrow } from "../Icons";

import { Dropdown } from "flowbite-react";
import { IOption } from "utils/constants";

import { useSelector } from "redux/hooks";

interface IProps {
  selectedOption: number;
  options: IOption[];
  maxCount?: number;
  onSelect: (option: IOption) => void;
  dataTestId?: string;
  regenerateImage?: boolean;
}

export const CountDD = memo(
  ({ selectedOption, options, maxCount, onSelect, dataTestId, regenerateImage }: IProps) => {
    const themeMode = useSelector((state) => state.authReducer.theme);

    return (
      <Dropdown
      className={classNames(styles.dropdownCustomBorder,{
        [styles.light]: themeMode === 'light',
        [styles.dark]: themeMode === 'dark',
      })} 
        label=''
        renderTrigger={() => (
          <div
          className={classNames(styles.menu, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
            [styles.regenerateImage]: regenerateImage
          })}
        >
          {selectedOption} <DownArrow />
        </div>
      )}
      >
        {options
          .filter((option) => option.value <= Number(maxCount))
          .map((option) => (
            <Dropdown.Item
              className={classNames("transition-none",styles.menuItem, {
                [styles.light]: themeMode === "light",
                [styles.dark]: themeMode === "dark",
                [styles.isSelected]: option.value === selectedOption,
              })}
              onClick={() => onSelect(option)}
              key={`${option.label}`}
              data-testid={dataTestId}
            >
              <div className={styles.text}>{option.label}</div>
            </Dropdown.Item>
          ))}
      </Dropdown>
    );
  }
);

